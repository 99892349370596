<template>
	<div class="clearfix loading-page-container">
		<div class="main-box">
			<div class="main-logo full centered_content">
				<div class="logo-content">
					<img
						class="navbar-brand-full"
						src="img/brand/thegreenshot-brand.png"
						onclick="document.location.href='my-dashboard#/my-dashboard'"
						alt="TheGreenShot"
					/>
				</div>
			</div>

			<div class="animated-logo-container">
				<video autoplay loop muted>
					<source :src="tgsAnimatedLogo" type="video/mp4" />
				</video>
			</div>
		</div>
	</div>
</template>

<script>
import tgsAnimatedLogo from './../../../public/videos/tgs-logo-animation.mp4';

export default {
	name: 'LoaderMainWaitingPage',
	data() {
		return {
			tgsAnimatedLogo
		};
	}
};
</script>

<style lang="scss" scoped>
.loading-page-container {
	background-color: #fff;
	width: 100vw;
	height: 100vh;
	position: relative;

	.main-box {
		width: 100%;
		max-width: 450px;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.animated-logo-container {
			width: 100%;
			max-width: 100%;
			display: flex;
			justify-content: center;
			overflow: hidden;

			video {
				transform: translateY(-2px);
				display: inline-block;
				max-width: 100%;
			}
		}

		.main-logo {
			height: 60px;
			margin-bottom: 100px;
			.logo-content {
				opacity: 0;
				transform: translateY(50px);
				transition: ease-in-out 800ms;
				animation: logoAnimation 800ms forwards;
				animation-delay: 300ms;
				width: 135px;
				height: 60px;
				overflow: hidden;
				position: relative;

				& > img {
					display: inline-block;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;
					left: auto;
					bottom: 0;
				}
			}
		}
	}
}

@keyframes logoAnimation {
	from {
		opacity: 0;
		transform: translateY(-50px);
	}

	to {
		opacity: 1;
		transform: translateY(0);
	}
}
</style>
