var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "clearfix loading-page-container" }, [
    _c("div", { staticClass: "main-box" }, [
      _vm._m(0),
      _c("div", { staticClass: "animated-logo-container" }, [
        _c(
          "video",
          {
            attrs: { autoplay: "", loop: "", muted: "" },
            domProps: { muted: true },
          },
          [
            _c("source", {
              attrs: { src: _vm.tgsAnimatedLogo, type: "video/mp4" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "main-logo full centered_content" }, [
      _c("div", { staticClass: "logo-content" }, [
        _c("img", {
          staticClass: "navbar-brand-full",
          attrs: {
            src: "img/brand/thegreenshot-brand.png",
            onclick: "document.location.href='my-dashboard#/my-dashboard'",
            alt: "TheGreenShot",
          },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }